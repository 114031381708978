import React from 'react';
import PropTypes from 'prop-types';
import errorBoundary from '@ifeng-fe/errorBoundary';

/**
 * 定义 PPX 组件
 */
class Ppx extends React.PureComponent {
    static propTypes = {
        content: PropTypes.string,
    };

    /**
     * 插入 Cooperation html
     */
    createCooperation = () => {
        return { __html: this.props.content };
    };

    /**
     * 渲染组件
     */
    render() {
        return (
            <div>
                <div dangerouslySetInnerHTML={this.createCooperation()} />
            </div>
        );
    }
}

export default errorBoundary(Ppx);
