import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { pageToSendSta } from '@ifeng-fe/container_content/common/client/utils/ppxLive';
import { rel } from '@ifeng-fe/container_content/common/client/utils/rel';

class PpLive extends React.PureComponent {
    static propTypes = {
        tag: PropTypes.string,
        content: PropTypes.array,
    };

    componentDidMount() {
        const { tag, content } = this.props;

        pageToSendSta(tag, content);
    }

    render() {
        const { tag, content } = this.props;

        return (
            <div className={styles.pplive}>
                <div className={styles.list}>
                    <ul className="clearfix">
                        {content.map((item, index) => (
                            <li key={index} className={item.type}>
                                <a
                                    href={
                                        item.pcClickUrl.indexOf('?') > -1
                                            ? `${item.pcClickUrl}&tag=${tag}`
                                            : `${item.pcClickUrl}?tag=${tag}`
                                    }
                                    target="_blank"
                                    rel={rel}
                                    style={{ background: `url(${item.avatarUrl})`, backgroundSize: 'cover' }}>
                                    <span>直播中</span>
                                </a>
                                <h3>{item.anchorName}</h3>
                                <p>{item.viewerDesc}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        );
    }
}

export default errorBoundary(PpLive);
