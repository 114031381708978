import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.css';
import errorBoundary from '@ifeng-fe/errorBoundary';
import { rel } from '@ifeng-fe/container_content/common/client/utils/rel';

class DataBox extends React.PureComponent {
    static propTypes = {
        content: PropTypes.object,
    };

    render() {
        const { content } = this.props;
        const { title, data } = content;

        return (
            <React.Fragment>
                <h3 className={styles.title}>
                    <span>{title}</span>
                </h3>
                <ul className={styles.list}>
                    {data.map((item, index) => (
                        <li key={index}>
                            <span className={index < 3 ? styles.red : styles.black}>{index + 1}</span>
                            <a href={item.url} title={item.title} target="_blank" rel={rel}>
                                {item.title && item.title.length < 27 ? item.title : `${item.title.slice(0, 27)}...`}
                            </a>
                        </li>
                    ))}
                </ul>
            </React.Fragment>
        );
    }
}

export default errorBoundary(DataBox);
